import axios from './index'

export default {
  getChartData(dataUrl) {
    return axios.get(dataUrl)
      .then((response) => {
        if (response.data) {
           return response.data;
        }
      }).catch((error) => {
        return Promise.reject(error);
      });
  },

  getAllChartsInTheDashboard() {
    return axios.get("/chart/AllChartsInTheDashboard")
      .then((response) => {
          return response.data;
      }).catch((error) => {
          return Promise.reject(error);
      });
  },

  getColorsForApexChart() {
    return axios.get("/data/shared/GetColorsForApexChart")
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
