<template>
  <b-col md="6">
    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" :body-class="`iq-bg-${bgColor} rounded`">
      <template v-slot:body>

        <b-row class="d-flex align-items-center justify-content-around">
          <b-col lg="8">
            <b-row class="d-flex align-items-center">
              <b-col lg="2" md="3" class="d-none d-md-block">
                <div :class="`rounded-circle iq-card-icon bg-${bgColor} mr-3`">
                  <font-awesome-icon :icon="`fa-regular ${boxIcon}`" />
                </div>
              </b-col>
              <b-col lg="10" md="9">
                <h2 class="mb-0"><span class="counter">{{ data.total }}</span></h2>
                <h5 class="">{{ $t(data.text) }}</h5>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4" class="rounded-sm mt-3 mt-lg-0">
            <router-link :to="{ name:data.routeName, params: data.routeProps }">
              <button :class="`btn btn-${bgColor} btn-block`">
                <span class="text-white">{{ $t(data.buttonText) }}</span>
                <i class="fa fa-chevron-right text-white" style="margin-left:6px;"></i>
              </button>
            </router-link>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-col>
</template>

<script>
    import chartService from '../../services/chart';

    export default {
        props: {
            dataUrl: String,
            bgColor: {
              type: String,
              default: "primary"
            },
            boxIcon: {
              type: String,
              default: "fa-calendar-check"
            }
        },
        data() {
            return {
                data: {}
            };
        },
        methods: {
            getData() {
                chartService.getChartData(this.dataUrl)
                    .then(response => {
                        if (response) {
                            this.data = response;
                        }
                    });
                },
        },
        mounted: function () {
            this.getData()
        }
    };
</script>
